@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    font-family:'VazirmatnRD'
}

.myghalam{
    font-family:'MyGhalam'
}