/* Generated by script */
@font-face {
  font-family: VazirmatnRD;
  src: url('./fonts/webfonts/Vazirmatn-RD-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirmatnRD;
  src: url('./fonts/webfonts/Vazirmatn-RD-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirmatnRD;
  src: url('./fonts/webfonts/Vazirmatn-RD-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirmatnRD;
  src: url('./fonts/webfonts/Vazirmatn-RD-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirmatnRD;
  src: url('./fonts/webfonts/Vazirmatn-RD-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirmatnRD;
  src: url('./fonts/webfonts/Vazirmatn-RD-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirmatnRD;
  src: url('./fonts/webfonts/Vazirmatn-RD-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirmatnRD;
  src: url('./fonts/webfonts/Vazirmatn-RD-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VazirmatnRD;
  src: url('./fonts/webfonts/Vazirmatn-RD-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
